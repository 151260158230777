body {
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    background: #f1f1f1;
}

.content-container {
    background: #fff;

    .content {
        padding: 15px 0px;
    }

    > .container > .content:first-child:last-child {
        padding: 3rem 0px;
    }
}
